<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Markisen / Vollkassettenmarkises / Piano <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Die Piano
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full md:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>

        <div class="col-span-full md:col-span-1">
          <h3
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Die Markise für Puristen
          </h3>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Die PIANO besticht nicht nur durch ihr herausragendes Design. In
            geschlossenem Zustand werden Markisentuch und -arme in der Kassette
            optimal vor Witterungseinflüssen geschützt. Damit ist die belastbare
            und langlebige Kassettenmarkise ein Spitzenprodukt unter den
            Sonnenschutzanlagen. <br /><br />
            Langlebigkeit und Sicherheit zeichnen hochwertige Markisen aus.
            Bauteile aus speziell gehärtetem Aluminium sind bei der PIANO die
            Grundlage für große Belastbarkeit und hohe Sicherheit. Die bewährten
            stabilen Kippgelenke sorgen für ein dauerhaft gutes Schließverhalten
            der Kassette.<br /><br />
            Die geradlinige und zeitlose Gestaltung Ihres Hauses sollte beim
            Sonnenschutz nicht Halt machen. Setzen Sie auch bei Ihrer Markise
            auf ein konsequent kubisches Design.
          </p>
        </div>
      </div>

      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Allgemein">
            <p class="text-lg">
              Die PIANO ist bis zu einer maximalen Breite von 600 cm und bis zu
              einem maximalen Ausfall von 350 cm erhältlich, oder einer
              maximalen Breite von 650 cm und einem maximalen Ausfall von 250
              cm. In der Standardausführung ist die Markise mit einem
              Elektromotor ausgestattet.<br /><br />
              Sie erhalten die PIANO in der Standardfarbe weiß (RAL 9010) oder
              in einer der 18 LEINER-LOUNGE-Feinstrukturfarben. Gegen Aufpreis
              realisieren wir außerdem jede gewünschte RAL-Farbe – lassen Sie
              sich von Ihrem Fachpartner beraten!
            </p>
          </tab>
          <tab title="Maßen">
            <div class="text-lg">
              <span class="font-bold">Breite:</span> <br />
              max. 650 cm - 700 cm <br />
              <span class="font-bold">Ausfall/Tiefe:</span> <br />
              max. 250 cm - 350 cm <br />
              <span class="font-bold">Winkelung:</span> <br />
              0° - 20° <br />
              <span class="font-bold">Ausstattung:</span> <br />
              LED <br />
              Safety Motion <br />
              WPS <br />
            </div>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/leiner/piano/head-web-piano-01.webp"),
          alt: "leiner vollkassettenmarkise piano",
        },
        {
          image: require("@/assets/img/leiner/piano/piano.webp"),
          alt: "piano 3d",
        },
        {
          image: require("@/assets/img/leiner/piano/head-web-piano-02.webp"),
          alt: "leiner piano nachts offen",
        },
        {
          image: require("@/assets/img/leiner/piano/head-web-piano-03.webp"),
          alt: "leiner piano nachts geschlossen mit beleuchtung",
        },
        {
          image: require("@/assets/img/leiner/piano/head-web-piano-04.webp"),
          alt: "leiner piano kasten",
        },
      ],
    };
  },
};
</script>
